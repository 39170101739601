<template>
  <div class="task_detail">
    <div class="main">
      <div class="task_items">
        <div class="one_box">
          <p class="p1">{{ task_name }}</p>
          <p class="p2">{{ start_time }}-{{ end_time }}</p>
        </div>
        <div class="tow_box">
          <p class="p1">班级 : {{ classes }}</p>
          <p class="p2">完成率 : {{ rate }}%</p>
        </div>
        <ul v-for="(item, index) in items" :key="index">
          <li>
            <p>
              运动训练模式: <span>{{ interpreter("train", item.train) }}</span>
            </p>
          </li>
          <li>
            <p>
              运动类型: <span>{{ interpreter("type", item.sports_type) }}</span>
            </p>
          </li>
          <li>
            <p>
              运动模式: <span>{{ interpreter("mode", item.mode) }}</span>
            </p>
          </li>
          <li>
            <p>
              组数: <span>{{ item.group_count }}个</span>
            </p>
          </li>
          <li>
            <p v-if="item.mode === 'TIME'">
              每组: <span>{{ item.per_group_val }}分钟</span>
            </p>
            <p v-else>
              每组: <span>{{ item.per_group_val }}{{ item.unit }}</span>
            </p>
          </li>
        </ul>
      </div>
      <el-table
        :data="students"
        stripe
        style="width: 100%"
        align="center"
        height="500px">
        <el-table-column prop="student.name" label="姓名"> </el-table-column>
        <el-table-column label="完成度" align="center">
          <template v-slot:default="{ row }">
            <ul class="completion" v-for="i in row.items" :key="i.id + ''">
              <li class="red" v-if="i.status === 0">
                【{{ interpreter("type", i.sports_type)
                }}{{ i.group_count }}组】 未完成
              </li>
              <li v-else>
                【{{ interpreter("type", i.sports_type)
                }}{{ i.group_count }}组】 已完成
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column label="作业点评" align="center">
          <template v-slot:default="{ row }">
            <el-button type="primary" size="mini" round @click="comment(row)"
              >作业点评</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="评分">
          <template v-slot:default="{ row }">
            <el-rate v-model="row.teacher_stars" disabled></el-rate>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      id: "",
      items: [],
      task_name: "",
      classes: {},
      end_time: "",
      start_time: "",
      rate: 0,
      dictionaries: {
        train: [
          {
            // AI: "智能训练模式",
            // CONVENTION: "常规训练模式",
            // INTEREST: "趣味训练模式"
          },
        ],
        type: [
          {
            JUMP: "跳绳",
            TRAMPOLINE: "蹦床",
            GYMNASTICS: "跳操",
            LONG_JUMP: "立定跳远",
            RUN_STEP: "跑步",
            BASKETBALL: "篮球",
            SIT_UP: "仰卧起坐",
            JUMPING: "开合跳",
            KICKER: "踢键子",
            SQUAT: "深蹲",
            HIGH_KNEE: "高抬腿",
            KICK_BUTTOCKS: "原地臀踢",
          },
        ],
        mode: [{ COUNT: "计数", TIME: "计时", FREE: "自由" }],
      },
      students: [],
    };
  },
  created() {
    this.id = this.getQueryValue("id");
    this.get_task_detail();
  },
  computed: {
    // 对照翻译字典
    interpreter() {
      return function (name, val) {
        //
        for (let t in this.dictionaries) {
          if (t == name) {
            for (let i in this.dictionaries[name][0]) {
              if (i == val) {
                return this.dictionaries[name][0][i];
              }
            }
          }
        }
      };
    },
  },
  methods: {
    to_task_list() {
      this.$router.push({ path: "/tasklist" });
    },
    async get_task_detail() {
      const res = await this.axios.get(`/homework/${this.id}`);
      if (res.code === 1) {
        this.items = res.data.items;
        this.task_name = res.data.name;
        this.classes = res.data.class_name;
        this.end_time = res.data.end_time;
        this.start_time = res.data.start_time;
        this.rate = res.data.progress;
        // this.rate = res.data.rate;
        this.students = res.data.studentHomeWork;
        this.form = res.data;
        console.log(this.task_name, "-task_name");
      }
    },
    comment(row) {
      console.log(row, "--homework_id");

      // if (row.status == 1) {
      this.$router.push({
        path: `/task_rate?student_id=${row.student_id}&homework_id=${row.id}&name=${row.student.name}&classname=${this.classes}`,
      });
      // } else {
      //   this.$message.warning("该学生作业未完成");
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.task_detail {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100%;
  .title {
    font-size: 20px;
    font-weight: normal;
    color: #5fa7ff;
    span {
      color: #999999;
      cursor: pointer;
    }
  }
  .task_items {
    color: #fff;
    background: #516cf7;
    box-shadow: 5px 5px 5px 1px rgb(174 203 255 / 75%);
    border-radius: 10px;
    padding: 20px;
    // margin-top: 30px;
    .one_box {
      display: flex;
      justify-content: space-between;
      .p1 {
        font-size: 25px;
      }
      .p2 {
        font-size: 14px;
      }
    }
    .tow_box {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .p1 {
        font-size: 18px;
        margin-right: 40px;
      }
      .p2 {
        font-size: 24px;
      }
    }
    ul {
      display: flex;
      // flex-wrap: wrap;
      justify-content: space-between;
      li {
        // width: 25%;
        margin-top: 20px;
        p {
          font-size: 18px;
          span {
            display: inline-block;
            font-size: 14px;
            background-color: rgba(255, 255, 255, 0.2);
            padding: 5px;
            min-width: 100px;
            text-align: center;
            border-radius: 5px;
            margin-left: 10px;
            // padding: 10px;
            // opacity: 0.2;
          }
        }
      }
    }
  }
  .completion {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    li {
      min-width: 200px;
      padding: 0px 10px;
      background: #73cd93;
      border-radius: 10px;
      color: #fff;
    }
    .red {
      background: #f15a24;
    }
  }
  .el-rate {
    .el-rate__icon {
      font-size: 20px;
    }
  }
}
</style>
